import React, {useEffect, useRef, useState} from "react";
import Leftside from "../Leftside";
import {observer} from "mobx-react-lite";
import RightContainer from "../RightContainer";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ServiceBox from "../Services/ServiceBox";
import useGetServices from "hooks/useGetServices";
import ServicesStore from "stores/ServicesStore";
import DataStore from "stores/DataStore";
import '../../css/Themes.scss'
import {useSearchParams} from "react-router-dom";
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";

const HomePage = () => {
    const { servicesData, servicesNames, selectedServiceCategory, setSelectedServiceCategory, loadedServices } = ServicesStore;
    const { accountConfig } = DataStore;

    const [showOptions, setShowOptions] = useState(false)
    const servicesList = useRef()
    const [params] = useSearchParams()

    const showServicesContainer = params.get('services') === 'true' || window.innerWidth > 1024

    useEffect(() => {
        setSelectedServiceCategory(servicesNames[0])
    }, [servicesNames])

    useEffect(() => {
      if(showServicesContainer){
        GAEvent(GAEventTypes.SERVICES_PAGE_VIEW);
      }
    }, [showServicesContainer]);    

    useOnClickOutside(servicesList, () => setShowOptions(false))

    useGetServices()

    const getSelectedServices = (selectedServiceCategory) => {
        const servicesCategory = Object.values(servicesData).find(service => service.name === selectedServiceCategory?.name)
        return servicesCategory
    }

    getSelectedServices(selectedServiceCategory)

    return (
        <>
            <div className={`${accountConfig.theme}-theme secondary-bg`}>
                <div className="lg:px-4 py-4 min-h-screen max-h-full" >
                    <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
                        <Leftside />
                        {showServicesContainer && <RightContainer>
                            {
                                loadedServices ? <>
                                    {servicesNames.length > 0 ? <>
                                        <div className={`flex mb-6 gap-4 flex-col sm:flex-row sm:items-center`}>
                                            <h4 className="mb-0">Choose a Service Category</h4>
                                            <div className="w-fit border border-black py-2 pl-4 pr-16 rounded-3xl relative cursor-pointer " ref={servicesList} onClick={() => setShowOptions(!showOptions)}>
                                                {selectedServiceCategory?.name}
                                                <div className="absolute top-[17px] right-[13px]">
                                                    <svg width="10" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1L9 9L17 1" stroke="#525252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                {showOptions && <ul className="absolute top-[calc(100%+6px)] left-0 p-0 bg-white border rounded" >
                                                    {servicesNames.map(service => {
                                                        return <li key={service.id} className="px-[16px] py-2 border-b hover:bg-gray-100 cursor-pointer duration-75 whitespace-nowrap" onClick={() => setSelectedServiceCategory(service)}>{service.name}</li>
                                                    })}
                                                </ul>}
                                            </div>
                                        </div>
                                        {getSelectedServices(selectedServiceCategory)?.available_services.map(service => <ServiceBox key={service.id} service={service} selectedServiceCategory={selectedServiceCategory} />)}
                                    </> : 'No Services Available For Online Booking'
                                    }
                                </> : null
                            }

                        </RightContainer>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default observer(HomePage);
