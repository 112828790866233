import ButtonPrimary from "component/Common/ButtonPrimary";
import CommonModal from "component/Common/CommonModal";
import ClinicsStore from "stores/ClinicsStore";

export function CallToScheduleServiceModal({ onClose }) {
  const { selectedClinic } = ClinicsStore;

  return (
    <CommonModal>
      <h5>
        We have the availability of the service you selected. Please call us so
        that our staff can schedule your treatment.
      </h5>
      <p className="w-full text-left mt-4">
        Call us{" "}
        <a href={`tel:${selectedClinic?.contact_no}`} className="no-underline">
          {selectedClinic?.contact_no}
        </a>
      </p>
      <ButtonPrimary text="Ok" onClick={onClose} />
    </CommonModal>
  );
}
